import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getSingleProductbystatus } from "../../../actions/singleProductAction";
import { getAllCategory } from "../../../actions/categoryAction";
import { getAllLocation } from "../../../actions/warehouseAction";
import { getAllAttribute } from "../../../actions/attributeAction";
import RenderTable from "./RenderTable";
import ActiveRenderTable from "./ActiveRendertable";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Skeleton,
  Tabs,
  Row,
  Col,
  AutoComplete,
  Divider,
  TreeSelect,
  Spin,
  Select,
  Checkbox,
  Space,
} from "antd";
const { Option } = Select;

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ProductList = ({
  getSingleProductbystatus,
  List,
  updatelist,
  setUpdatelist,
  setselectedproduct,
  totalitems,
  getAllCategory,
  categoryList,
  Location,
  getAllAttribute,
  attributelist,
  brandlist,
}) => {
  const [data, setdata] = useState(false);
  const [products, setproducts] = useState(false);
  const loading = useRef(true);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const keytab = useRef(true);
  const keyward = useRef("");
  const searchCategory = useRef("");
  const searchColor = useRef("");
  const searchDicounted = useRef("");
  const searchSellable = useRef("");
  const searchLocation = useRef("");
  const [reload, setreload] = useState(false);

  const onChange = (event) => {
    if (event.keyCode == 13) {
      pageno.current = 1;
      keyward.current = event.target.value;
      setreload(true);
    }
  };

  function callback(key) {
    console.log(key);
    pageno.current = 1;
    if (key == 1) {
      keytab.current = true;
    } else {
      keytab.current = false;
    }
    setreload(true);
  }

  useEffect(() => {
    getAllAttribute();
    getAllCategory();
    getAllLocation();
    getSingleProductbystatus(
      keytab.current,
      keyward.current,
      searchCategory.current,
      searchColor.current,
      "",
      pageno.current,
      page_size.current,
      searchSellable.current,
      "",
      "",
      searchDicounted.current,
      // searchLocation.current
    ).then((result) => {
      setproducts(result);
      setUpdatelist(true);
      setdata(true);
      setreload(false);
    });
  }, [reload]);

  const onDicountChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      searchDicounted.current = 0;
    }
    else {
      searchDicounted.current = "";
    }
    setreload(true);
  };
  const onsellableChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      searchSellable.current = true;
    }
    else {
      searchSellable.current = "";
    }
    setreload(true);
  };


  const rendertable = () => {
    if (reload) {
      return (
        <>
          <span style={{ marginTop: "30px" }}>
            <Spin indicator={antIcon} /> loading
          </span>
        </>
      );
    }
  };

  if (data) {
    // console.log(attributelist[1].terms);
    return (
      <>
        <Row>
          <Col span={8}>
            <h3>Enter product title for instant Search</h3>
            <AutoComplete
              placeholder="input search text"
              onKeyUp={onChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={7} >
            <h3>Category</h3>
            <TreeSelect
              style={{ width: "100%" }}
              treeData={categoryList}
              onChange={(value) => {
                pageno.current = 1;
                searchCategory.current = value;
                setreload(true);
              }}
            />
          </Col>
          <Col span={3} >
            <h3>Color</h3>
            <Select
              showSearch
              placeholder="Please pick a color"
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                pageno.current = 1;
                searchColor.current = value;
                setreload(true);
              }}
            >
              <Option value="">ALL</Option>
              {attributelist[1].terms.map((color) => {
                return <Option value={color.id}>{color.name}</Option>;
              })}
            </Select>
          </Col>
          <Col offset={1}>
            <Space direction="vertical">

              <Checkbox onChange={onDicountChange}>Show Discount products</Checkbox>
              <Checkbox onChange={onsellableChange}>Show sellable products</Checkbox>

            </Space>

          </Col>
        </Row>
        <Divider />
        <Spin spinning={reload}>
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Active Products" key="1">
              <ActiveRenderTable
                List={products.results}
                setUpdatelist={setUpdatelist}
                setselectedproduct={setselectedproduct}
                pageno={pageno}
                page_size={page_size}
                totalitems={totalitems}
                setreload={setreload}
                brandlist={brandlist}
              />
            </TabPane>
            <TabPane tab="Inactive Products" key="2">
              <RenderTable
                List={products.results}
                setUpdatelist={setUpdatelist}
                setselectedproduct={setselectedproduct}
                pageno={pageno}
                page_size={page_size}
                totalitems={totalitems}
                setreload={setreload}
                brandlist={brandlist}
              />
            </TabPane>
          </Tabs>
        </Spin>
      </>
    );
  } else {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    List: state.singleProduct.sinleproductlist.results,
    totalitems: state.singleProduct.sinleproductlist.count,
    categoryList: state.category.categorylist,
    Location: state.warehouse.locationlist,
    attributelist: state.attribute.attributelist,
    brandlist: state.brand.brandlist,
  };
};

export default connect(mapStateToProps, {
  getSingleProductbystatus,
  getAllCategory,
  getAllAttribute,
})(ProductList);
