import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Drawer, Divider, Col, Row, Button, message, Popconfirm, Table } from "antd";
import { Link } from "react-router-dom";
import { deleteUserRole } from "../../../actions/userRoleAction";
import { deletePromotion, getFilteredPromotion } from "../../../actions/promotionAction";
import CreateNewPromotion from "./CreateNewPromotion";
import Edit from "./Edit";
import moment from "moment";

const DescriptionItem = ({ title, content, reload, SetReload, }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const UserRoleDetails = ({ details, deleteUserRole, setUpdatelist, auth, reload, setReload, deletePromotion, getFilteredPromotion }) => {
  const [visible, setVisible] = useState(false);
  const promotions = useRef([])

  const columns = [
    {
      title: 'Date',
      align: 'center',
      dataIndex: 'created',
      key: 'created',
      render: text => <span>{moment(text).format("DD-MM-YYYY")}</span>,
    },
    {
      title: 'Previous',
      align: 'center',
      dataIndex: 'previous_designation_name',
      key: 'previous_designation_name',
    },
    {
      title: 'new',
      align: 'center',
      dataIndex: 'new_designation_name',
      key: 'new_designation_name',
    },
    {
      title: 'Amount',
      align: 'right',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: "Action",
      dataIndex: "",
      align: 'center',
      key: "x",
      render: (details) => (
        <Button type="text" danger >
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => {
              confirmPromotion(details)
            }}
            okText="Yes"
            cancelText="No"
          >
            <Link to="#">remove</Link>
          </Popconfirm>
        </Button>
      ),
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const confirmPromotion = (details) => {
    deletePromotion(details.id).then(e => {
      setReload(!reload)
      setVisible(false);
      message.success("Removed");
    });
  };

  const confirm = () => {
    deleteUserRole(details.id).then(e => {
      setReload(!reload)
      setVisible(false);
      message.success(
        details.name + " Has been deleted from your role list"
      );
    });
  };

  return (
    <>
      <Link to="#" onClick={showDrawer} key={details.id}>
        View Details
      </Link>

      <Drawer
        width={640}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <p
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          Information
        </p>
        <p className="site-description-item-profile-p">Salary details</p>
        <Row>
          <Col span={12}>
            <DescriptionItem title="employee" content={details.employeeName} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Department" content={details.employeeDepartment} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Designation" content={details.employeeRole} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Area" content={details.employeeBranch} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Basic Salary" content={details.monthlySalary} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Gross Salary" content={details.grossSalary} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Wage/Hour" content={details.perHourWageDay} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Wage/Day" content={details.dailyWage} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Overtime/Hour" content={details.overtime} />
          </Col>
        </Row>

        <Row >
          <Col span={12}>
            <DescriptionItem title="House Rent" content={details.houseRent} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Medical Allowance" content={details.medAllowance} />
          </Col>
        </Row>
        <Row >
          <Col span={12}>
            <DescriptionItem title="Food Allowance" content={details.foodAllowance} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Convenience" content={details.convenience} />
          </Col>
        </Row>
        <Row >
          <Col span={12}>
            <DescriptionItem title="Holiday Allowance" content={details.holidayAllowance} />
          </Col>
          <Col span={12}>
            <DescriptionItem title="Attendance Bonus" content={details.attendanceBonus} />
          </Col>
        </Row>


        {auth.permissions.includes("HRM.Salary Setup_is_delete") ? <Button danger style={{ marginRight: "10px", marginTop: "30px" }}>
          <Popconfirm
            title="Are you sure to delete this contact?"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Link to="#">Delete</Link>
          </Popconfirm>
        </Button> : ""}

        {auth.permissions.includes("HRM.Salary Setup_is_read") ? <Edit style={{ marginTop: "30px" }} details={details} reload={reload} setReload={setReload} /> : ""}

        <CreateNewPromotion details={details} reload={reload} setReload={setReload} />
        <Divider />
        <p className="site-description-item-profile-p">Promotion History</p>



        <Table dataSource={details.promotions} columns={columns} />;
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { deleteUserRole, deletePromotion, getFilteredPromotion })(UserRoleDetails);
