import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import { getspecificSingleProduct } from "../../../actions/singleProductAction";
import PicturesWall from "./PictureWall";
import ShowImage from "./ShowImage";
import { getspecificproductvariation } from "../../../actions/variableProductAction";
import Log from "./Log";
import BarcodePrinter from "./BarcodePrinter";
import BarcodePrinterElor from "./BarcodePrinterElor";
import BarcodePrinterHorizontal from "./BarcodePrinterHorizontal";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Divider,
  Button,
  Affix,
  Skeleton,
  Space
} from "antd";
import { RightOutlined } from "@ant-design/icons";
const { Content } = Layout;

const ProductVariationsQuickView = ({
  getspecificSingleProduct,
  details,
  getspecificproductvariation,
  Variations,
  auth,

}) => {
  const { id } = useParams();
  const [newimage, setnewimage] = useState(false);
  const [loading, setloading] = useState(false);
  const colors = new Set();
  const color_ids = new Set();
  const variations = useRef();
  const variations_id = useRef();

  useEffect(() => {
    setloading(true)
    getspecificSingleProduct(id).then(resp => {
      getspecificproductvariation(id).then((res) => {
        // console.log(res)
        setloading(false);
      });
    })
  }, []);

  if (loading) {
    return <Skeleton active />;
  } else {
    return (
      <>

        <Layout className="window-frame">
          <Sidebar />
          <Layout className="site-layout">
            <Navbar />
            <Content className="main-frame-content">
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Products</Breadcrumb.Item>
                <Breadcrumb.Item>{details.title}</Breadcrumb.Item>
                <Breadcrumb.Item>Variations</Breadcrumb.Item>
              </Breadcrumb>
              <div className="site-layout-background main-frame">
                <Affix offsetTop={20}>
                  {auth.permissions.includes(
                    "Products.All products_is_read"
                  ) ? (
                    <Link
                      aria-current="page"
                      to="/product"
                      style={{ float: "right" }}
                    >
                      <Button
                        type="primary"
                        style={{ backgroundColor: "#F0F2F5", color: "black" }}
                      >
                        Go to the Product list <RightOutlined />
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </Affix>
                <Row>
                  <Col span={8}>Product name:</Col>
                  <Col span={16}><b>{details.title}</b></Col>

                  <Col span={8}>Slug:</Col>
                  <Col span={16}>{details.slug}</Col>

                  <Col span={8}>Product code:</Col>
                  <Col span={16}>{details.product_code}</Col>

                  <Col span={8}>Category:</Col>
                  <Col span={16}>{details.main_category}</Col>

                  <Col span={8}>Sub category</Col>
                  <Col span={16}>{details.category_name}</Col>

                  <Col span={8}>Discount:</Col>
                  <Col span={16}>
                    {details.discount ? details.discount : 0}
                    {details.discount_type ? details.discount_type : ""}
                  </Col>
                  <Col span={8}>Stock Alert:</Col>
                  <Col span={16}>{details.stock_alart_amount}</Col>
                  <Col span={8}>Short Description:</Col>
                  <Col span={16}>{details.Short_description}</Col>
                </Row>
                <Divider></Divider>
                {Variations.map((Variation) => {
                  return (
                    <>
                      <Row>
                        {/* <Col span={8}>{renderImage(Variation)}</Col> */}
                        <Col span={7}>
                          <Row>
                            <Col span={10}>Color:</Col>
                            <Col span={14}>{Variation.color}</Col>
                          </Row>
                          <Row>
                            <Col span={10}>Size:</Col>
                            <Col span={14}>{Variation.size}</Col>
                          </Row>
                          <Row>
                            <Col span={10}>Warehouse:</Col>
                            <Col span={14}>{Variation.Warehouse_name}</Col>
                          </Row>
                          <Row>
                            <Col span={10}>Price:</Col>
                            <Col span={14}>
                              {Variation.selling_price}
                            </Col>
                          </Row>
                          <Row>
                            <Col span={10}>Quantity:</Col>
                            <Col span={14}>{Variation.quantity}</Col>
                          </Row>
                        </Col>
                        <Col
                          span={7}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Space style={{ margin: "0px" }}>
                            <div>
                              {details?.brand == "ELOR" ?
                                <BarcodePrinterElor
                                  details={details}
                                  variation={Variation}
                                /> : <BarcodePrinter
                                  details={details}
                                  variation={Variation}
                                />}
                            </div>
                            <BarcodePrinterHorizontal
                              details={details}
                              variation={Variation}
                            />
                          </Space>
                        </Col>
                        <Col span={7} offset={2}>
                          {details?.brand == "ANZARA" ?
                            <Log type={20} ID={Variation.id} /> : <Log type={22} ID={Variation.id} />
                          }
                        </Col>


                      </Row>
                      <Divider></Divider>
                    </>
                  );
                })}

              </div>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    details: state.singleProduct.singleproduct,
    Variations: state.ProductDetails.productdetails,
  };
};

export default connect(mapStateToProps, {
  getspecificSingleProduct,
  getspecificproductvariation,
})(ProductVariationsQuickView);
