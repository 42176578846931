import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import { getspecificproductvariationForEdit } from "../../../actions/variableProductAction";
import { getAllBrand } from "../../../actions/brandAction";
import BarcodePrinter from "./BarcodePrinter";
import BarcodePrinterElor from "./BarcodePrinterElor";
import BulkBarcodePrint from "./BulkBarcodePrint";
import BarcodePrinterHorizontal from "./BarcodePrinterHorizontal";
import ReactToPrint from "react-to-print";
import Log from "./Log";

import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Button,
  Col,
  Row,
  Select,
  message,
  TreeSelect,
  Divider,
  Drawer,
  Image,
  Space,
} from "antd";

const { Option } = Select;

const Quickview = ({ details, Variations, getspecificproductvariationForEdit, getAllBrand, brandlist }) => {
  const [visible, setVisible] = useState(false);
  const componentRef = useRef();
  const wrapper_ref = React.useRef();
  const showDrawer = () => {

    getspecificproductvariationForEdit(details.id).then((res) => {
      setVisible(true);
    });
  };

  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "BDT",
  });

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <>
        <a href="#" onClick={showDrawer} style={{ margin: 4 }}>
          Quick View
        </a>
        <Drawer
          title={details.title}
          width="80%"
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {visible ? (
            <>
              {/* <ReactToPrint
                trigger={() => <button>Print this out!</button>}
                content={() => componentRef.current}
              />
              <div
                style={
                  {
                    // display: "none"
                  }
                }
              >
                <div
                  ref={componentRef}
                  style={{ padding: "10px" }}
                  className="invoice_print_fontSize"
                >
                  {Variations.map((Variation) => {
                    let page = "";
                    for (var i = 1; i <= Variation.quantity; i++) {
                      page += barcodeDesign(i, details, Variation);
                      console.log(barcodeDesign(i, details, Variation));
                    }
                  })}
                </div>
              </div> */}

              <Row gutter={16}>
                <Col span={8}>Product name:</Col>
                <Col span={16}>{details.title}</Col>

                <Col span={8}>Slug:</Col>
                <Col span={16}>{details.slug}</Col>

                <Col span={8}>Product code:</Col>
                <Col span={16}>{details.product_code}</Col>

                <Col span={8}>Category:</Col>
                <Col span={16}>{details.main_category}</Col>

                <Col span={8}>Sub category</Col>
                <Col span={16}>{details.category_name}</Col>

                <Col span={8}>Discount:</Col>
                <Col span={16}>
                  {details.discount ? details.discount : 0}
                  {details.discount_type ? details.discount_type : ""}
                </Col>
                <Col span={8}>Stock Alert:</Col>
                <Col span={16}>{details.stock_alart_amount}</Col>
                <Col span={8}>Short Description:</Col>
                <Col span={16}>{details.Short_description}</Col>
              </Row>
              {/* <BulkBarcodePrint details={details} Variations={Variations} /> */}
              <Divider></Divider>
              {details.variations.map((Variation) => {
                return (
                  <>
                    <Row>
                      {/* <Col span={8}>{renderImage(Variation)}</Col> */}
                      <Col span={7}>
                        <Row>
                          <Col span={10}>Color:</Col>
                          <Col span={14}>{Variation.color}</Col>
                        </Row>
                        <Row>
                          <Col span={10}>Size:</Col>
                          <Col span={14}>{Variation.size}</Col>
                        </Row>
                        <Row>
                          <Col span={10}>Warehouse:</Col>
                          <Col span={14}>{Variation.Warehouse_name}</Col>
                        </Row>
                        <Row>
                          <Col span={10}>Price:</Col>
                          <Col span={14}>
                            {/* {Variation.discounted_price !=
                            Variation.selling_price ? (
                              <>
                                <del>{Variation.selling_price}</del>{" "}
                                {Variation.discounted_price}
                              </>
                            ) : (
                              Variation.selling_price
                            )} */}
                            {Variation.selling_price}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>Quantity:</Col>
                          <Col span={14}>{Variation.quantity}</Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        {details?.brand == "ANZARA" ?
                          <Log type={20} ID={Variation.id} /> : <Log type={22} ID={Variation.id} />
                        }
                      </Col>
                      {/* <Col span={7} style={{ textAlign: "center" }}>
                        <BarcodePrinter
                          details={details}
                          variation={Variation}
                        />
                      </Col>
                      <Col span={7} style={{ textAlign: "center" }}>
                        <BarcodePrinterHorizontal
                          details={details}
                          variation={Variation}
                        />
                      </Col> */}
                      <Col
                        span={8}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Space style={{ margin: "0px" }}>
                          <div>
                            {/* <BarcodePrinter
                              details={details}
                              variation={Variation}
                            // style={{ transform: "rotate(90deg)" }}
                            /> */}
                            {details?.brand == "ELOR" ?
                              <BarcodePrinterElor
                                details={details}
                                variation={Variation}
                              // style={{ transform: "rotate(90deg)" }}
                              /> : <BarcodePrinter
                                details={details}
                                variation={Variation}
                              // style={{ transform: "rotate(90deg)" }}
                              />}

                          </div>
                          <BarcodePrinterHorizontal
                            details={details}
                            variation={Variation}
                          />
                        </Space>
                      </Col>
                    </Row>
                    <Divider></Divider>
                  </>
                );
              })}
            </>
          ) : (
            ""
          )}
        </Drawer>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Variations: state.ProductDetails.productdetails,
    brandlist: state.brand.brandlist,
  };
};

export default connect(mapStateToProps, { getspecificproductvariationForEdit, getAllBrand })(
  Quickview
);
