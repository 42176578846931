import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import html2canvas from "html2canvas";
import { getTransferItem } from "../../actions/transfer";

import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Button,
  Col,
  Row,
  Select,
  message,
  TreeSelect,
  Space,
  Divider,
  Drawer,
  Image,
  Skeleton,
} from "antd";

const { Option } = Select;

const Quickview = ({ details, getTransferItem, businessprofile }) => {
  var formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });
  const componentRef = useRef();
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);
  const serialcount = useRef(0);
  const count = useRef(0);
  const total = useRef(0);

  const showDrawer = () => {
    getTransferItem(details.id).then((service) => {
      // console.log(service)
      total.current = 0;
      service.map((item) => {
        total.current += item.quantity;
      });
      setdata(service);
      count.current = service.length;
      if (count.current < 10) count.current = 10 - count.current;
      else {
        count.current = 0;
      }
      setloading(false);
    });
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };



  const renderitems = () => {
    if (loading) {
      return <Skeleton active />;
    } else {
      return data.map((item, index) => {
        console.log("data : ", item);
        serialcount.current = index + 1;
        return (
          <>
            <tr>
              <td>{index + 1}</td>
              <td>
                {/* {" "}
                {item.Product[0].product_code}{" "} -- {item.Product[0].title}{" "} | {item.Product[0].Attribute_details}
                <small>
                  {console.log(item.Product[0])}
                  {item.Product[0].color
                    ? item.Product[0].color + " | " + item.Product[0].size
                    : ""}
                </small> */}
                {item?.title}
                <small>{" "}{item?.attribute}</small>
              </td>
              <td>{item?.selling_price}</td>
              <td style={{ textAlign: "center", margin: "auto" }}>
                {item.quantity}
              </td>
            </tr>
          </>
        );
      });
    }
  };

  const renderblanktables = () => {
    if (loading) {
      return "";
    } else {
      let indexcount = 0;
      return Array.apply(null, Array(count.current)).map(() => {
        indexcount = indexcount + 1;
        return (
          <tr>
            <td>{serialcount.current + indexcount}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      });
    }
  };

  return (
    <>
      <>
        <a href="#" onClick={showDrawer} style={{ margin: 4 }}>
          Quick View
        </a>
        <Drawer
          width="700"
          onClose={onClose}
          visible={visible}
        // bodyStyle={{ paddingBottom: 80 }}
        >
          <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <div
              ref={componentRef}
              style={{ padding: "10px" }}
              className="wordrobe_print_fontSize"
            >
              <Row
                style={{
                  borderBottom: "2px solid lightgray",
                  paddingBottom: "5px",
                  marginBottom: "20px",
                }}
              >
                <Col span={15} style={{ paddingTop: "10px" }}>
                  <small>
                    <div
                      style={{ lineHeight: "2.5px" }}
                      dangerouslySetInnerHTML={{
                        __html: businessprofile.address,
                      }}
                    ></div>
                  </small>
                </Col>

                <Col span={9} style={{ textAlign: "right" }}>
                  <img
                    src={businessprofile.logo}
                    style={{
                      maxHeight: "60px",
                      right: "0",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={13}>
                  <Row style={{ margin: "auto", textAlign: "left" }}>
                    <Col span={4}>
                      <h3>From :</h3>
                    </Col>
                    <Col span={20} style={{ textAlign: "left" }}>
                      {details.source ? <h4>{details.Source[0].name}</h4> : ""}
                    </Col>
                  </Row>
                  <Row style={{ margin: "auto", textAlign: "left" }}>
                    <Col span={4}>
                      <h3>To :</h3>
                    </Col>
                    <Col span={20} style={{ textAlign: "left" }}>
                      {details.destance ? (
                        <h4>{details.Destance[0].name}</h4>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={11}>
                  <Row style={{ margin: "auto", textAlign: "right" }}>
                    <Col span={13}>
                      <h3>Challan No.</h3>
                    </Col>
                    <Col span={10} offset={1} style={{ textAlign: "right" }}>
                      {details.tansfer_number ? (
                        <h4>{details.tansfer_number}</h4>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "auto", textAlign: "right" }}>
                    <Col span={13}>
                      <h3>Issue Date</h3>
                    </Col>
                    <Col span={10} offset={1} style={{ textAlign: "right" }}>
                      {details.issue_date}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <table className="product_table invoice_print_fontSize">
                <tbody>
                  <tr style={{ fontWeight: "500" }}>
                    <td>NO</td>
                    <td>PRODUCT DETAILS</td>
                    <td>PRICE</td>
                    <td>QTY</td>
                  </tr>
                  {renderitems()}
                  {renderblanktables()}
                  <tr style={{ fontWeight: "500" }}>
                    <td></td>
                    <td></td>
                    <td>Total</td>

                    <td>{total.current}</td>
                  </tr>
                </tbody>
              </table>

              <Row style={{ minHeight: "60px", marginTop: "10px" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  <img
                    src={businessprofile.signature}
                    style={{
                      maxHeight: "60px",
                      left: "0",
                    }}
                  />
                </Col>
                <Col span={12} style={{ textAlign: "center" }}></Col>
              </Row>
              <Row>
                <Col span={12} style={{ textAlign: "left" }}>
                  <h3
                    style={{
                      borderTop: "2px solid black",
                      display: "inline-block",
                    }}
                  >
                    AUTHORISED SIGNATURE
                  </h3>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <h3
                    style={{
                      marginBottom: "0px",
                      padding: "0px",
                      borderTop: "2px solid black",
                      // width: "50%",
                      display: "inline-block",
                    }}
                  >
                    RECEIVER SIGNATURE
                  </h3>
                  <br></br>
                  <small>(WITH DATE)</small>
                </Col>
              </Row>
            </div>
          </div>

          <Row>
            <Col span={13}>
              <Row style={{ margin: "auto", textAlign: "left" }}>
                <Col span={4}>
                  <h3>From :</h3>
                </Col>
                <Col span={20} style={{ textAlign: "left" }}>
                  {details.source ? <h4>{details.Source[0].name}</h4> : ""}
                </Col>
              </Row>
              <Row style={{ margin: "auto", textAlign: "left" }}>
                <Col span={4}>
                  <h3>To :</h3>
                </Col>
                <Col span={20} style={{ textAlign: "left" }}>
                  {details.destance ? <h4>{details.Destance[0].name}</h4> : ""}
                </Col>
              </Row>
            </Col>
            <Col span={11}>
              <Row style={{ margin: "auto", textAlign: "right" }}>
                <Col span={24}>
                  <h2>{details.status}</h2>
                </Col>
                <Col span={13}>
                  <h3>Challan No.</h3>
                </Col>
                <Col span={10} offset={1} style={{ textAlign: "right" }}>
                  {details.tansfer_number ? (
                    <h4>{details.tansfer_number}</h4>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row style={{ margin: "auto", textAlign: "right" }}>
                <Col span={13}>
                  <h3>Issue Date</h3>
                </Col>
                <Col span={10} offset={1} style={{ textAlign: "right" }}>
                  {details.issue_date}
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <table className="product_table">
            <tbody>
              <tr style={{ fontWeight: "500" }}>
                <td>NO</td>
                <td>PRODUCT DETAILS</td>
                <td>PRICE</td>
                <td>QTY</td>
              </tr>
              {renderitems()}
              {renderblanktables()}
              <tr style={{ fontWeight: "500" }}>
                <td></td>
                <td></td>
                <td>Total</td>

                <td>{total.current}</td>
              </tr>
            </tbody>
          </table>
          <Divider />
          <Row style={{ minHeight: "80px" }}>
            <Col span={12} style={{ textAlign: "left" }}>
              <img
                src={businessprofile.signature}
                style={{
                  maxHeight: "80px",
                  left: "0",
                }}
              />
            </Col>
            <Col span={12} style={{ textAlign: "center" }}></Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              <h3
                style={{
                  borderTop: "2px solid black",
                  display: "inline-block",
                }}
              >
                AUTHORISED SIGNATURE
              </h3>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <h3
                style={{
                  marginBottom: "-10px",
                  padding: "0px",
                  borderTop: "2px solid black",
                  // width: "50%",
                  display: "inline-block",
                }}
              >
                RECEIVER SIGNATURE
              </h3>
              <br></br>
              <small>(WITH DATE)</small>
            </Col>
          </Row>
        </Drawer>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
  };
};

export default connect(mapStateToProps, { getTransferItem })(Quickview);